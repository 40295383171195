/*
 * CompanyInfoPage Messages
 *
 * This contains all the text for the CompanyInfoPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.CompanyInfoPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Công ty',
  },
  basicInfo: {
    id: `${scope}.basicInfo`,
    defaultMessage: 'Thông Tin Cơ bản',
  },
  companyInfoRequired: {
    id: `${scope}.companyInfoRequired`,
    defaultMessage: 'Vui lòng nhập {field}!',
  },
  companyName: {
    id: `${scope}.companyName`,
    defaultMessage: 'Tên Công Ty',
  },
  companyAddress: {
    id: `${scope}.companyAddress`,
    defaultMessage: 'Điạ chỉ công ty',
  },
  phone: {
    id: `${scope}.phone`,
    defaultMessage: 'Số Điện Thoại',
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Lưu',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Huỷ',
  },
  district: {
    id: `${scope}.district`,
    defaultMessage: 'Quận',
  },
  city: {
    id: `${scope}.city`,
    defaultMessage: 'Thành phố',
  },
  redInvoiceInfo: {
    id: `${scope}.redInvoiceInfo`,
    defaultMessage: 'Thông tin hoá đơn đỏ',
  },
  companyImage: {
    id: `${scope}.companyImage`,
    defaultMessage: 'Logo công ty',
  },
  editCompany: {
    id: `${scope}.editCompany`,
    defaultMessage: 'Chỉnh sửa thông tin công ty',
  },
  remove: {
    id: `${scope}.remove`,
    defaultMessage: 'Xoá',
  },
  uploadImage: {
    id: `${scope}.uploadImage`,
    defaultMessage: 'Chọn hình ảnh',
  },
  editInfo: {
    id: `${scope}.editInfo`,
    defaultMessage: 'Chỉnh sửa thông tin',
  },
  taxCode: {
    id: `${scope}.taxCode`,
    defaultMessage: 'Mã Số Thuế',
  },
  address: {
    id: `${scope}.address`,
    defaultMessage: 'Địa chỉ',
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Tên',
  },
  companyInfo: {
    id: `${scope}.companyInfo`,
    defaultMessage: 'Công ty',
  },
  'Ho Chi Minh': {
    id: `${scope}.city.Ho Chi Minh`,
    defaultMessage: 'Hồ Chí Minh',
  },
  'District 1': {
    id: `${scope}.district.District 1`,
    defaultMessage: 'Quận 1',
  },
  'District 2': {
    id: `${scope}.district.District 2`,
    defaultMessage: 'Quận 2',
  },
  'District 3': {
    id: `${scope}.district.District 3`,
    defaultMessage: 'Quận 3',
  },
  'District 4': {
    id: `${scope}.district.District 4`,
    defaultMessage: 'Quận 4',
  },
  'District 5': {
    id: `${scope}.district.District 5`,
    defaultMessage: 'Quận 5',
  },
  'District 6': {
    id: `${scope}.district.District 6`,
    defaultMessage: 'Quận 6',
  },
  'District 7': {
    id: `${scope}.district.District 7`,
    defaultMessage: 'Quận 7',
  },
  'District 8': {
    id: `${scope}.district.District 8`,
    defaultMessage: 'Quận 8',
  },
  'District 9': {
    id: `${scope}.district.District 9`,
    defaultMessage: 'Quận 9',
  },
  'District 10': {
    id: `${scope}.district.District 10`,
    defaultMessage: 'Quận 10',
  },
  'District 11': {
    id: `${scope}.district.District 11`,
    defaultMessage: 'Quận 11',
  },
  'District 12': {
    id: `${scope}.district.District 12`,
    defaultMessage: 'Quận 12',
  },
  'Binh Tan District': {
    id: `${scope}.district.Binh Tan District`,
    defaultMessage: 'Quận Bình Tân',
  },
  'Binh Thanh District': {
    id: `${scope}.district.Binh Thanh District`,
    defaultMessage: 'Quận Bình Thạnh',
  },
  'Go Vap District': {
    id: `${scope}.district.Go Vap District`,
    defaultMessage: 'Quận Gò Vấp',
  },
  'Phu Nhuan District': {
    id: `${scope}.district.Phu Nhuan District`,
    defaultMessage: 'Quận Phú Nhuận',
  },
  'Tan Binh District': {
    id: `${scope}.district.Tan Binh District`,
    defaultMessage: 'Quận Tân Bình',
  },
  'Tan Phu District': {
    id: `${scope}.district.Tan Phu District`,
    defaultMessage: 'Quận Tân Phú',
  },
  'Thu Duc District': {
    id: `${scope}.district.Thu Duc District`,
    defaultMessage: 'Quận Thủ Đức',
  },
  'Nha Be District': {
    id: `${scope}.district.Nha Be District`,
    defaultMessage: 'Quận Nhà Bè',
  },
  'Binh Chanh District': {
    id: `${scope}.district.Binh Chanh District`,
    defaultMessage: 'Quận Bình Chánh',
  },
  'Hoc Mon District': {
    id: `${scope}.district.Hoc Mon District`,
    defaultMessage: 'Quận Hóc Môn',
  },
  orderApprovalFunc: {
    id: `${scope}.orderApprovalFunc`,
    defaultMessage: 'Chức năng duyệt đơn hàng',
  },
  active: {
    id: `${scope}.active`,
    defaultMessage: 'Hoạt động',
  },
  inactive: {
    id: `${scope}.inactive`,
    defaultMessage: 'Không hoạt động',
  },
  orderApprovalFuncDes: {
    id: `${scope}.orderApprovalFuncDes`,
    defaultMessage: 'Đơn hàng cần được duyệt bởi các người dùng được chỉ định trước khi gửi tới Nhà Cung Cấp.',
  },
  orderApprovalFuncModalTitle: {
    id: `${scope}.orderApprovalFuncModalTitle`,
    defaultMessage: 'You can not inactivate Order Approval Function!',
  },
  orderApprovalFuncModalDecs: {
    id: `${scope}.orderApprovalFuncModalDecs`,
    defaultMessage: 'Please make sure all Pending Approval Orders were approved',
  },
  goToButton: {
    id: `${scope}.goToButton`,
    defaultMessage: 'Approve Orders',
  },
  vaAccountInformation: {
    id: `${scope}.vaAccountInformation`,
    defaultMessage: 'Thông tin chuyển khoản',
  },
  vaAccount: {
    id: `${scope}.vaAccount`,
    defaultMessage: 'Số tài khoản',
  },
  vaAccountName: {
    id: `${scope}.vaAccountName`,
    defaultMessage: 'Tên tài khoản',
  },
  bank: {
    id: `${scope}.bank`,
    defaultMessage: 'Ngân hàng',
  },
  QRCode: {
    id: `${scope}.QRCode`,
    defaultMessage: 'Mã QR',
  },
});
